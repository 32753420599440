.PP_banner1{
    position: relative;
    display: block;
    height: 13.020833333333334vw;
    filter: brightness(75%);
}
.PP_head1{
    color: white;
    position: absolute;
    top: 38%;
    left: 44%;
    font-size: 1.8229166666666667vw;

}

.PP_card1{
    height: 43vw;
    width: 100%;
    border: none;
}

.PP_head2{
    color: #173C67;
    position: relative;
    left: 44%;
    top: 3vw;
    font-size: 1.5625vw;
}

.PP_p1{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 300%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 0.9vw;
}


@media (max-width: 800px){
    .PP_banner1{
        display: none;
    }

    .box5{
        position: relative;
        display: block;
        width: 100%;
        height: 300px;
        background: url("../img/banner_3.jpg") no-repeat;
        background-size: 250vw;
        background-position: top;
        filter: brightness(75%);
    }

    .PP_head1{
        z-index: 999;
        font-size: 35px !important;
        top: 20% !important;
        left: 23% !important;
        width: 100%;
    }

    .PP_card1{
        height: 800px;
    }

    .PP_head2{
        font-size: 30px !important;
        left: 25% !important;
        top: -100px !important;
    }

    .PP_p1{
        font-size: 15px !important;
        text-align: center;
        margin: 5% 5%;

        left: 0% !important;
        top: -12% !important;
    }


}