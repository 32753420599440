







.card1{
    height: 25vw;
    border: none;
}

.card2{

    border: none;
}

.card3{
    border: none;
    height: 20vw;

}

.card4{
    border: none;
    height: 35vw;

}




.lawyer_banner_1{
    display: block;
    height: 13.020833333333334vw;
    filter: brightness(75%);
}
.first_head{
    color: white;
    position: absolute;
    top: 38%;
    left: 44%;
    font-size: 1.8229166666666667vw;

}
.second_head{
    color: #173C67;
    position: relative;
    left: 44%;
    top: 3vw;
    font-size: 1.5625vw;


}

.first_p{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 300%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 0.9vw;


}
.third_head{
    color: #173C67;
    position: relative;
    left: 36%;
    top: 1.5vw;
    font-size: 1.3020833333333333vw;
    white-space: normal;

}

.second_p{
    color: #6a6a6a;

    position: relative;
    left: 8%;
    top: 3vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;


}
.app_store{
    position: relative;
    color: #fff;
    background-color: #4abdcf;
    padding: 0.625vw;
    border-radius: 0.20833333333333334vw;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 0.9375vw;
    line-height: 1.1458333333333333vw;
    top: 5vw;
    left: 9%;
    width: 11vw;

}
.app_store:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;
}

.play_store{
    position: relative;
    color: #fff;
    background-color: #267f8d;
    padding: 0.625vw;
    border-radius: 0.20833333333333334vw;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 0.9375vw;
    line-height: 1.1458333333333333vw;
    top: 2.6vw;
    left: 30%;
    width: 11vw;
}

.play_store:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;
}

.second_p_f{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 5vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;

}

.lawyer_phone{
    position: relative;
    bottom: 18vw;
    left: 60%;
    width: 35%;
    border-radius: 1.3020833333333333vw;

}

.lawyer_tools_header{
    position: relative;
    color: #173C67;
    top: 5vw;
    font-size: 1.3020833333333333vw;
    left: 38%;

}


.first_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 6vw;
    left: 8%;
    text-decoration: underline;

}
.first_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 7vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;


}
.analytic_pic{
    position: relative;
    bottom: 2.5vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}

.second_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 5vw;
    left: 8%;
    text-decoration: underline;

}
.second_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 6vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;

}
.assistance_pic{
    position: relative;
    bottom: 6vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}
.third_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 0vw;
    left: 8%;
    text-decoration: underline;


}
.third_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 1vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;
}
.files_pic{
    position: relative;
    bottom: 10vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}

.fourth_head{
    position: relative;
    color: #173C67;
    font-size: 1.3020833333333333vw;
    top: 2vw;
    left: 38%;


}


.fourth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 4vw;
    left: 30%;


}
.fifth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 2.6vw;
    left: 58%;

}
.sixth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 4vw;
    left: 43%;

}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.box{
    display: none;
}

.appear{
    display: none;
}



@media (max-width: 800px){
    .banner_card{
        display: none;

    }
    .lawyer_banner_1{
        display: none;
    }



    .box1{
        position: relative;
        display: block;
        width: 100%;
        height: 300px;
        background: url("../img/banner_3.jpg") no-repeat;
        background-size: 400vw;
        background-position: top;
        filter: brightness(75%);

    }

    .first_head{
        position: absolute;
        z-index: 999;
        font-size: 35px !important;
        top: 100px !important;
        left: 20% !important;
        width: 100%;

    }

    .card1{
        height: 1350px !important;
    }

    .second_head{
        font-size: 30px !important;
        left: 24% !important;
        top: 0px !important;
    }
    .first_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0px !important;

    }
    .disappear{
        display: none;
    }
    .appear{
        display: block;
    }

    .card4{
        height: 1150px !important;
    }

    .third_head{
        font-size: 30px !important;
        text-align: center;
        left: 0% !important;
        top: 0px !important;
    }
    .second_p{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0px !important;
    }
    .app_store{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        height: 4%;
        width: 40% !important;

        left: 0% !important;
        top: 0px !important;


    }
    .play_store{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        height: 4%;
        width: 40% !important;

        left: 50% !important;
        top: -81px !important;

    }
    .second_p_f{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -6.5% !important;

    }
    .lawyer_phone{
        width: 100% !important;
        left: 0% !important;
    }

    .card2{
        height: 700px !important;
    }
    .lawyer_tools_header{
        font-size: 30px !important;
        text-align: center;
        left: 0% !important;
        top: 0px !important;

    }
    .first_subheader{
        font-size: 20px !important;
        left: 23% !important;
        top: 30px !important;
    }
    .first_subp{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 20px !important;

    }
    .analytic_pic{
        width: 100% !important;
        left: 0% !important;
        top: 2% !important;
        height: 30% !important;

    }

    .second_subheader{
        font-size: 20px !important;
        left: 25% !important;
        top: 0px !important;
    }
    .second_subp{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0px !important;

    }
    .assistance_pic{
        width: 100% !important;
        left: 0% !important;
        top: 2% !important;
        height: 30% !important;
    }

    .third_subheader{
        font-size: 20px !important;
        left: 27% !important;
        top: 0px !important;
    }
    .third_subp{
        font-size: 15px !important;
        text-align: center !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0px !important;
    }
    .files_pic{
        width: 100% !important;
        left: 0% !important;
        top: 2% !important;
        height: 40% !important;
    }

    .card3{
        height: 300px !important;
    }


    .fourth_head{
        font-size: 30px !important;
        text-align: center;
        left: 0% !important;
        top: 15% !important;
    }
    .fourth_subheader{
        font-size: 20px !important;
        left: 31% !important;
        top: 21% !important;
    }
    .fifth_subheader{
        font-size: 20px !important;
        left: 35% !important;
        top: 24% !important;
    }
    .sixth_subheader{
        font-size: 20px !important;
        left: 25% !important;
        top: 27% !important;
    }





}

