.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto;


}


.tech_banner1_card{
    height: 12vw;
    border: none;
}


.tech_banner1{
    width: 100%;
    position: relative;
    display: block;
    bottom: 145%;

    filter: brightness(75%);

}

.tech_banner1_head{
    color: white;
    position: absolute;
    top: 38%;
    left: 42%;
    font-size: 2vw;
    text-align: center;
}


.FAQ_card{
    border: none;

    height: 25vw;
}

.FAQ_header_card{
    height: 6vw;
    border: none;
}

.FAQ_header{
    color: #173C67;
    position: relative;
    left: 35%;
    top: 3vw;
    font-size: 2vw;
}

.ticket_card{
    height: 15vw;
    border: none;

}

.ticket_header{
    color: #173C67;
    position: relative;
    left: 18%;
    top: 3vw;
    font-size: 1.5vw;
}

.ticket_button{
    position: absolute;

    color: #fff;
    background-color: #30b849;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 18%;
    top: 40%;
    font-size: 1vw;
    line-height: 100%;
}
.ticket_button:hover{
     background: #000066;
     color: white;
     transition: all 0.3s ease-out;
 }

.beta_warning{
    color: red;
    position: relative;
    left: 33%;
    top: 30%;
    font-size: 0.8vw;
}


.tech_banner1-5, .tech_banner1-5_head{
    display: none;
}


@media (max-width: 800px){

    .tech_banner1_card{
        display: none;
    }

    .tech_banner1-5_head{
        display: block;
        color: white;
        position: absolute;
        z-index: 999;
        font-size: 35px !important;
        top: 215px !important;
        left: 28% !important;
        width: 100%;
    }

    .FAQ_header_card{
        height: 100px;

    }

    .FAQ_header{
        font-size: 28px !important;
        left: 5% !important;
        top: 50px !important;
    }


    .FAQ_card{
        height: 300px !important;
    }


    .ticket_card{
        height: 300px;
    }
    .ticket_header{
        font-size: 25px !important;
        left: 20% !important;
        top: 0px !important;
    }

    .ticket_button{
        padding: 5px 40px;
        font-size: 15px;
        top: 60px;
        left: 20%;
        width: 55%;
        border-radius: 4px;
    }

    .beta_warning{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 25% !important;
        text-align: center;
    }






}