.contact_form{

    margin-top: 7vw;
    height: 35vw;
}

.submit_btn{
    background-color: white;
    border: none;

}


@media (max-width: 800px){
    .contact_form{
        width: 100%;
        height: 800px;

    }
}
