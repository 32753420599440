.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto;


}

.app_banner_card{
    height: 12vw;
    border: none;
}


.app_banner{
    position: relative;
    display: block;
    height: 13.020833333333334vw;
    filter: brightness(75%);


}

.app_banner_head{
    color: white;
    position: absolute;
    top: 38%;
    left: 46%;
    font-size: 2vw;
    text-align: center;
}

.app_header1_card{
    height: 20vw;
    border: none;
}

.app_header_1{
    color: #173C67;
    position: relative;
    left: 40%;
    top: 3vw;
    font-size: 2vw;

}

.app_header1_p{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 250%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 1vw;

}

.app_header2_card{
    height: 20vw;
    border: none;
}

.app_header_2{
    color: #173C67;
    position: relative;
    left: 40%;
    top: 3vw;
    font-size: 2vw;

}

.app_header2_p{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 250%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 1vw;

}


.app_download_card{
    height: 35vw;
    border: none;
}
.app_download_banner{
    width: 100%;
    top: 10%;

    filter: brightness(75%);
}

.app_download_head{
    position: absolute;
    top: 35%;
    font-size: 2.5vw;
    left: 17%;
    width: 100%;
    color: white;
}
.app_download_p{
    position: absolute;
    top: 45%;
    font-size: 0.9vw;
    left: 17%;
    width: 100%;
    color: white;
}
.app_down_app{
    position: absolute;

    color: #fff;
    background-color: #4abdcf;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 17%;
    top: 60%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;

}
.app_down_app:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
}

.app_down_play{
    position: absolute;
    color: #fff;
    background-color: #267f8d;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 30%;
    top: 60%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;
}

.app_down_play:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
}