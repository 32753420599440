
.card1{
    height: 25vw;
    border: none;
}

.card2{

    border: none;
}

.card3{
    border: none;
    height: 20vw;

}

.card4{
    border: none;
    height: 35vw;

}




.banner_1{
    position: relative;
    display: block;
    height: 13.020833333333334vw;
    filter: brightness(75%);
}
.first_head{
    color: white;
    position: absolute;
    top: 38%;
    left: 44%;
    font-size: 1.8229166666666667vw;

}
.second_head{
    color: #173C67;
    position: relative;
    left: 44%;
    top: 3vw;
    font-size: 1.5625vw;


}

.first_p{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 300%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 0.9vw;


}
.third_head{
    color: #173C67;
    position: relative;
    left: 36%;
    top: 1.5vw;
    font-size: 1.3020833333333333vw;
    white-space: normal;

}

.second_p{
    color: #6a6a6a;

    position: relative;
    left: 8%;
    top: 3vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;


}
.app_store{
    position: relative;
    color: #fff;
    background-color: #4abdcf;
    padding: 0.625vw;
    border-radius: 0.20833333333333334vw;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 0.9375vw;
    line-height: 1.1458333333333333vw;
    top: 5vw;
    left: 9%;
    width: 11vw;

}
.app_store:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;
}

.play_store{
    position: relative;
    color: #fff;
    background-color: #267f8d;
    padding: 0.625vw;
    border-radius: 0.20833333333333334vw;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 0.9375vw;
    line-height: 1.1458333333333333vw;
    top: 2.6vw;
    left: 30%;
    width: 11vw;
}

.play_store:hover{
    background-color: #000066;
    border-radius: 4px;
    transition: all .5s ease-out;
}

.second_p_f{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 5vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;

}

.lawyer_phone{
    position: relative;
    bottom: 18vw;
    left: 60%;
    width: 35%;
    border-radius: 1.3020833333333333vw;

}

.lawyer_tools_header{
    position: relative;
    color: #173C67;
    top: 5vw;
    font-size: 1.3020833333333333vw;
    left: 38%;

}


.first_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 6vw;
    left: 8%;
    text-decoration: underline;

}
.first_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 7vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;


}
.analytic_pic{
    position: relative;
    bottom: 2.5vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}

.second_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 5vw;
    left: 8%;
    text-decoration: underline;

}
.second_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 6vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;

}
.assistance_pic{
    position: relative;
    bottom: 6vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}
.third_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 0vw;
    left: 8%;
    text-decoration: underline;


}
.third_subp{
    color: #6a6a6a;
    position: relative;
    left: 8%;
    top: 1vw;
    line-height: 200%;
    text-align: left;
    font-size: 0.9vw;
}
.files_pic{
    position: relative;
    bottom: 10vw;
    height: 20.833333333333332vw;
    left: 60%;
    width: 30%;
    border-radius: 1.3020833333333333vw;
}

.fourth_head{
    position: relative;
    color: #173C67;
    font-size: 1.3020833333333333vw;
    top: 2vw;
    left: 38%;


}


.fourth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 4vw;
    left: 30%;


}
.fifth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 2.6vw;
    left: 58%;

}
.sixth_subheader{
    position: relative;
    color: #173C67;
    font-size: 1.0416666666666667vw;
    top: 4vw;
    left: 43%;

}

