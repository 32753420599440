.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto;


}


.banner1_card{
    display: block;
    height: 12vw;
    border: none;
}


.banner1{
    width: 100%;
    position: relative;
    display: block;
    bottom: 145%;

    filter: brightness(75%);

}

.banner1_head{
    color: white;
    position: absolute;
    top: 38%;
    left: 45%;
    font-size: 2vw;
    text-align: center;
}


.banner1-5{
    display: none;
}

.banner1-5_head{
    display: none;
}


.story_card{
    height: 20vw;
    border: none;
}

.story_head{
    color: #173C67;
    position: relative;
    left: 45%;
    top: 3vw;
    font-size: 2vw;

}

.story_p{
    color: #6a6a6a;
    position: relative;
    top: 4vw;
    line-height: 250%;
    text-align: center;
    font-weight: bold;
    white-space: normal;
    font-size: 1vw;

}

.mission_card{
    height: 35vw;
    border: none;

}

.mission_head{
    color: #173C67;
    position: relative;
    top: 3vw;
    font-size: 2vw;
    left: 8%;

}
.mission_logo{
    position: relative;
    width: 2%;
    top: 1%;
    left: 20%;
}

.mission_p{
    color: #6a6a6a;
    position: relative;
    top: 2vw;
    line-height: 250%;
    font-size: 1vw;
    left: 8%;

}

.mission_img{
    position: relative;
    bottom: 18vw;
    left: 60%;
    width: 35%;
    border-radius: 1.3020833333333333vw;

}

.vision_card{
    height:35vw;
    border: none;
}

.vision_head{
    color: #173C67;
    position: relative;
    top: 3vw;
    font-size: 2vw;
    left: 60%;

}
.vision_logo{
    position: relative;
    width: 2%;
    top: 0.5vw;
    left: 71%;

}

.vision_p{
    color: #6a6a6a;
    position: relative;
    top: 2vw;
    line-height: 250%;
    white-space: normal;
    font-size: 1vw;
    left: 60%;

}
.vision_img{
    position: relative;
    bottom: 10vw;
    left: 10%;
    width: 35%;
    border-radius: 1.3020833333333333vw;

}

.values_card{
    height:35vw;
    border: none;

}

.values_head{
    color: #173C67;
    position: relative;
    top: 3vw;
    font-size: 2vw;
    left: 42%;


}

.values_logo{
    position: relative;
    width: 2%;
    top: 0.25vw;
    left: 58%;

}




.value1{
    position: relative;
    top: 10vw;
    left: 15%;
    font-size: 1.5vw;

}

.value1_img{
    position: relative;
    top: 5vw;
    left: 15%;
    width: 15%;
    border-radius: 1.3020833333333333vw;

}
.value1_p{
    position: relative;
    top: 10vw;
    left: 15%;
    font-size: 1vw;

}


.value2{
    position: relative;
    top: -5vw;
    left: 43%;
    font-size: 1.5vw;

}

.value2_img{
    position: relative;
    top: -10vw;
    left: 43%;
    width: 15%;
    border-radius: 1.3020833333333333vw;
}
.value2_p{
    position: relative;
    top: -5vw;
    left: 43%;
    font-size: 1vw;
}


.value3{
    position: relative;
    bottom: 19.5vw;
    left: 69%;
    font-size: 1.5vw;


}
.value3_img{
    position: relative;
    bottom: 25vw;
    left: 69%;
    width: 15%;
    border-radius: 1.3020833333333333vw;

}
.value3_p{
    position: relative;
    bottom: 19.5vw;
    left: 69%;
    font-size: 1vw;
}





.better_card{
    height:35vw;
    border: none;

}

.betterhead1{
    color: #173C67;
    position: relative;
    top: 3vw;
    font-size: 2vw;
    left: 38%;


}
.betterhead2{
    color: #173C67;
    position: relative;
    top: 3.2vw;
    font-size: 2.5vw;
    left: 32%;
}

.better_p{
    color: #6a6a6a;
    position: relative;
    top: 6vw;
    line-height: 250%;
    font-size: 1vw;
    left: 8%;
}

.horizontal-line{
    position: relative;
    width:0.01%;
    height:45%;
    top: -11.5vw;
    background-color: grey;
    left: 50%;
}

.check6{
    position: relative;
    bottom: 25vw;
    left: 55%;
    width: 1.5%;

}

.check6_p{
    color: #6a6a6a;
    position: relative;
    bottom: 27vw;
    line-height: 200%;
    font-size: 1vw;
    left: 58%;

}

.check7{
    position: relative;
    bottom: 22vw;
    left: 55%;
    width: 1.5%;

}

.check7_p{
    color: #6a6a6a;
    position: relative;
    bottom: 24vw;
    line-height: 200%;
    font-size: 1vw;
    left: 58%;

}

.download_card{
    height: 35vw;
    border: none;
}
.download_banner{
    width: 100%;
    top: 10%;

    filter: brightness(75%);
}

.download_head{
    position: absolute;
    top: 35%;
    font-size: 2.5vw;
    left: 17%;
    width: 100%;
    color: white;
}
.download_p{
    position: absolute;
    top: 45%;
    font-size: 0.9vw;
    left: 17%;
    width: 100%;
    color: white;
}
.down_app{
    position: absolute;

    color: #fff;
    background-color: #4abdcf;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 17%;
    top: 60%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;

}
.down_app:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
}

.down_play{
    position: absolute;
    color: #fff;
    background-color: #267f8d;
    padding: 0.8% 2%;
    border-radius: 0.22701475595913734vw;
    outline: none;
    border: none;
    cursor: pointer;
    left: 30%;
    top: 60%;
    font-size: 1.0215664018161181vw;
    line-height: 100%;
}

.down_play:hover{
    background-color: #000066;
    border-radius: 0.22701475595913734vw;
    transition: all .5s ease-out;
}

.box2{
    display: none;
}

.box3{
    display: none;
}



@media (max-width: 800px){
    .banner1_card{
       display: none;

    }

    .banner1{
        display: none;

    }

    .banner1_head{
        display: none;
    }

    .box2{
        position: relative;
        display: block;
        width: 100%;
        height: 300px;
        background: url("../img/hp_banner.jpg") no-repeat;
        background-size: 200vw;
        background-position: top;
        filter: brightness(75%);
    }
    .banner1-5_head{
        display: block;
        color: white;
        position: absolute;
        z-index: 999;
        font-size: 35px !important;
        top: 215px !important;
        left: 32% !important;
        width: 100%;
    }

    .story_card{
        height: 450px;
    }
    .story_head{
        font-size: 30px !important;
        left: 32% !important;
        top: 20px !important;
    }
    .story_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0px !important;
    }

    .mission_card{
        height: 800px;
    }

    .mission_head{
        font-size: 30px !important;
        left: 25% !important;
        top: 0px !important;
    }
    .mission_logo{
        width: 8%;
        left: 69%;
        top: -5.5%;
    }
    .mission_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -5% !important;
        text-align: center;
    }
    .mission_img{
        width: 100% !important;
        left: 0% !important;
        top: -5% !important;
        height: 30% !important;
    }
    .vision_card{
        height: 500px;
    }
    .vision_head{
        font-size: 30px !important;
        left: 25% !important;
        top: 3% !important;
    }
    .vision_logo{
        width: 8%;
        left: 64%;
        top: -5%;
    }
    .vision_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -5% !important;
        text-align: center;
    }
    .vision_img{
        width: 100% !important;
        left: 0% !important;
        top: -5% !important;
        height: 45% !important;
    }

    .values_card{
        height: 1400px;
    }
    .values_head{
        font-size: 30px !important;
        left: 15% !important;
        top: 3% !important;
    }
    .values_logo{
        width: 8%;
        left: 75%;
        top: -0.5%;
    }
    .value1{
        font-size: 25px !important;
        left: 25% !important;
        top: -15% !important;
    }
    .value1_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -17% !important;
        text-align: center;
    }
    .value1_img{
        width: 100% !important;
        left: 0% !important;
        top: 10% !important;
        height: 250px !important;
    }

    .value2{
        font-size: 25px !important;
        left: 25% !important;
        top: -13% !important;
    }
    .value2_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -13% !important;
        text-align: center;
    }
    .value2_img{
        width: 100% !important;
        left: 0% !important;
        top: 15% !important;
        height: 250px !important;
    }

    .value3{
        font-size: 25px !important;
        left: 13% !important;
        top: -10% !important;
    }

    .value3_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: -10% !important;
        text-align: center;

    }

    .value3_img{
        width: 100% !important;
        left: 0% !important;
        top: 17% !important;
        height: 250px!important;
    }

    .better_card{
        height: 1000px;
    }
    .betterhead1{
        font-size: 30px !important;
        left: 11% !important;
        top: 0% !important;
    }
    .betterhead2{
        font-size: 27px !important;
        left: 4% !important;
        top: 0% !important;
    }
    .better_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 0% !important;
        text-align: center;
    }
    .horizontal-line{
        display: none;
    }

    .check6{
       display: none;
    }
    .check6_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 5% !important;
        text-align: center;
    }

    .check7{
        display: none;
    }

    .check7_p{
        font-size: 15px !important;
        margin: 5% 5%;
        left: 0% !important;
        top: 10% !important;
        text-align: center;
    }

    .download_card{
        display: none;
    }

    .download_banner{
        display: none;


    }

    .box3{
        position: relative;
        display: block;
        width: 100%;
        height: 300px;
        background: url("../img/bg_download.jpg") no-repeat;
        background-size: 250vw;
        background-position: top;
        filter: brightness(75%);
    }
    .download_head{
        position: absolute;
        z-index: 999;
        top: 110px;
        font-size: 25px;
        left: 30px;
        width: 100%;

    }

    .download_p{
        position: absolute;
        z-index: 999;
        font-size: 12px;
        left: 30px;
        top: 150px;
        text-align: left;
    }


    .down_app{
        position: absolute;
        z-index: 999;
        padding: 5px 40px;
        font-size: 15px;
        top: 220px;
        left: 5%;
        width: 40%;
        border-radius: 4px;

    }
    .down_play{
        position: absolute;
        z-index: 999;
        padding: 5px 40px;
        font-size: 15px;
        top: 220px;
        left: 55%;
        width: 40%;
        border-radius: 4px;
    }






}

